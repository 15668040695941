import { ActionIcon, Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconArrowLeft, IconPlus } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { User } from 'api/domain/entities/user'
import { useListUsers } from 'api/query/user'
import { Aside } from 'components/aside/aside'
import { AsideEditUserContent } from 'components/aside/content/aside-edit-user-content'
import { AddUserDrawer } from 'components/drawer/add.user'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export function Users() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [opened, { open, close }] = useDisclosure()
  const { data: users, isLoading: isLoadingUsers } = useListUsers(id)
  const [selectedUser, setSelectedUser] = useState<User | undefined>()

  const columns: ColumnDef<User>[] = [
    {
      id: 'name',
      accessorKey: 'fullName',
      header: t('admin:columns.user.name'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: t('admin:columns.user.email'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'role',
      accessorKey: 'role',
      header: t('admin:columns.user.role'),
      cell: (col) => {
        return <Text size="sm">{t(`admin:fields.user-role.${col.getValue() as string}`).toUpperCase()}</Text>
      },
    },
  ]

  if (isLoadingUsers) {
    return <Loading size="xl" />
  }

  return (
    <>
      <Table<User>
        title={
          <Flex align="center">
            <ActionIcon size="sm" variant="subtle" radius="xl" onClick={() => navigate('/admin/tenants')}>
              <IconArrowLeft />
            </ActionIcon>
            <Text ml={10} size="1.375rem" fw={700}>
              {t('pages.users')}
            </Text>
            <Text size="1.375rem" c="gray.6" ml={5} fw={700}>
              {`(${users?.length})`}
            </Text>
          </Flex>
        }
        columns={columns}
        data={users || []}
        actionButtons={() => [
          <ActionIcon onClick={open} size="md" variant="subtle">
            <IconPlus size={20} />
          </ActionIcon>,
        ]}
        onRowClick={(row) => setSelectedUser(row.original)}
      >
        <Aside close={() => setSelectedUser(undefined)} opened={!!selectedUser}>
          {selectedUser && <AsideEditUserContent user={selectedUser} close={() => setSelectedUser(undefined)} />}
        </Aside>
      </Table>
      <AddUserDrawer opened={opened} close={close} />
    </>
  )
}
