import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import translationsFr from './locales/fr/trans.json'
import translationsEn from './locales/en/trans.json'

import sessionFr from './locales/fr/session.json'
import sessionEn from './locales/en/session.json'

import onboardingFr from './locales/fr/onboarding.json'
import onboardingEn from './locales/en/onboarding.json'

import adminFr from './locales/fr/admin.json'
import adminEn from './locales/en/admin.json'

import costEn from './locales/en/cost.json'
import costFr from './locales/fr/cost.json'

import tenantEn from './locales/en/tenant.json'
import tenantFr from './locales/fr/tenant.json'

import accountEn from './locales/en/account.json'
import accountFr from './locales/fr/account.json'

import userEn from './locales/en/user.json'
import userFr from './locales/fr/user.json'

export enum Language {
  EN = 'en',
  FR = 'fr',
}

export enum NS {
  translation = 'translation',
  session = 'session',
  onboarding = 'onboarding',
  cost = 'cost',
  tenant = 'tenant',
  account = 'account',
  user = 'user',
  admin = 'admin',
}

void i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [Language.FR]: {
        [NS.translation]: translationsFr,
        [NS.session]: sessionFr,
        [NS.onboarding]: onboardingFr,
        [NS.cost]: costFr,
        [NS.admin]: adminFr,
        [NS.tenant]: tenantFr,
        [NS.account]: accountFr,
        [NS.user]: userFr,
      },
      [Language.EN]: {
        [NS.translation]: translationsEn,
        [NS.session]: sessionEn,
        [NS.onboarding]: onboardingEn,
        [NS.cost]: costEn,
        [NS.admin]: adminEn,
        [NS.tenant]: tenantEn,
        [NS.account]: accountEn,
        [NS.user]: userEn,
      },
    },
  })

export default i18next
