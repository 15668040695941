import { DonutChart, DonutChartCell } from '@mantine/charts'
import { formatCost } from 'utils/number'

interface DashboardDonutProps {
  data: DonutChartCell[]
}

export function Donut({ data }: DashboardDonutProps) {
  return (
    <DonutChart
      tooltipDataSource="segment"
      valueFormatter={(value) => {
        if (value === 1) {
          return '1'
        }
        return formatCost(value, 0)
      }}
      size={400}
      thickness={70}
      h={340}
      w={300}
      data={data}
      startAngle={180}
      endAngle={0}
      chartLabel={(() => {
        const total = data.reduce((acc, curr) => acc + curr.value, 0)
        if (total === 1) {
          return '0$'
        }

        return formatCost(total)
      })()}
      styles={{
        label: {
          transform: 'translate(0, -5%)',
          fontSize: 30,
        },
      }}
    />
  )
}
