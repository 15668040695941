import { Box, Text, TextInput, Select, Group, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { User } from 'api/domain/entities/user'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AppRole } from 'api/dto/user'
import { useDeleteUserTenant, useUpdateUserTenant } from 'api/query/tenant'

interface AsideUserContentProps {
  user: User
  close: () => void
}

type PartialUser = {
  firstName?: string
  lastName?: string
  email?: string
  role?: AppRole
}

export function AsideEditUserContent({ user, close }: AsideUserContentProps) {
  const { id } = useParams()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: updateUserTenant } = useUpdateUserTenant(id)
  const { mutateAsync: deleteUserTenant } = useDeleteUserTenant(id)

  const form = useForm<PartialUser>({
    initialValues: {
      ...user,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.firstName || values.firstName.length < 1) {
        errors.firstName = t('common.required')
      }

      if (!values.lastName || values.lastName.length < 1) {
        errors.lastName = t('common.required')
      }

      if (!values.email || values.email.length < 1) {
        errors.email = t('common.required')
      }

      if (!values.role || values.role.length < 1) {
        errors.role = t('common.required')
      }

      return errors
    },
  })

  useEffect(() => {
    form.setValues({ ...user })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  async function onSubmit(partialUser: PartialUser) {
    setIsLoading(true)
    await updateUserTenant(partialUser)
    close()
    form.reset()
    setIsLoading(false)
  }

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text c="dark.7" lh="24px" size="1rem" fw={700}>
          {t('user:edit.description')}
        </Text>
        <Box
          flex={1}
          sx={{
            overflowY: 'auto',
          }}
        >
          <TextInput disabled mt={10} withAsterisk w="100%" label={t('user:fields.firstName')} {...form.getInputProps('firstName')} />
          <TextInput disabled mt={10} withAsterisk w="100%" label={t('user:fields.lastName')} {...form.getInputProps('lastName')} />
          <TextInput disabled mt={10} type="email" withAsterisk w="100%" label={t('user:fields.email')} {...form.getInputProps('email')} />
          <Select
            mt={10}
            w="100%"
            label={t('user:fields.app-role')}
            data={Object.entries(AppRole).map(([key, value]) => ({
              label: t(`user:fields.app-role-type.${value}`),
              value,
            }))}
            {...form.getInputProps('role')}
          />
        </Box>
        <Group mt={20} justify="space-between">
          <Button
            color="red"
            loading={isLoading}
            onClick={() => {
              if (!user.id) {
                return
              }

              setIsLoading(true)
              void deleteUserTenant(user.id)
              close()
              setIsLoading(false)
            }}
            size="xs"
          >
            {t('common.delete')}
          </Button>
          <Button loading={isLoading} type="submit" size="xs">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </>
  )
}
