import { Center, Group, Loader, MantineSize } from '@mantine/core'

interface LoadingProps {
  size?: MantineSize
  height?: string
}

export function Loading({ size = 'sm', height = '100vh' }: LoadingProps) {
  return (
    <Group h={height} justify="center">
      <Center>
        <Loader size={size} />
      </Center>
    </Group>
  )
}
