import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { Account } from 'api/domain/entities/account'
import { AccountDto, AccountFactory } from 'api/dto/account'
import axios from 'config/axios'
import { useCallback } from 'react'

export const useListAccounts = (tenantId?: string) =>
  useQuery({
    queryKey: ['tenants', tenantId, 'accounts'],
    queryFn: async () => {
      const { data } = await axios.get<AccountDto[]>(`/tenants/${tenantId}/accounts`)
      return data
    },
    select: useCallback((accounts: AccountDto[]) => accounts.map((a) => new Account(a)), []),
    staleTime: 1000 * 60 * 5,
    enabled: !!tenantId,
  })

export const useGetAccount = (tenantId: string, accountId: string) =>
  useQuery({
    queryKey: ['tenants', tenantId, 'accounts', accountId],
    queryFn: async () => {
      const { data } = await axios.get<AccountDto>(`/tenants/${tenantId}/accounts/${accountId}`)
      return data
    },
    select: useCallback((account: AccountDto) => new Account(account), []),
    staleTime: 1000 * 60 * 5,
  })

export const useCreateAccount = () =>
  useMutation({
    mutationFn: async (account: Partial<AccountDto>) => {
      const createAccountDto = AccountFactory.toCreateUpdateDto(account)

      const { data } = await axios.post<AccountDto>(`/tenants/${account.tenantId}/accounts`, { ...createAccountDto, tenantId: undefined })
      return data
    },
    onSuccess: ({ tenantId }) => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants', tenantId, 'accounts'],
      })
    },
  })

export const useUpdateAccount = () =>
  useMutation({
    mutationFn: async (account: Partial<AccountDto>) => {
      const updateAccountDto = AccountFactory.toCreateUpdateDto(account)

      const { data } = await axios.put<AccountDto>(`/tenants/${account.tenantId}/accounts/${account.id}`, {
        ...updateAccountDto,
        tenantId: undefined,
      })
      return data
    },
    onSuccess: ({ tenantId }) => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants', tenantId, 'accounts'],
      })
    },
  })
