import { Box, Divider, Flex, Text, TextInput, Select, Group, Button, MultiSelect } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Account } from 'api/domain/entities/account'
import { defaultRegions } from 'api/dto/region'
import { useUpdateAccount } from 'api/query/account'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type PartialAccount = {
  regions?: string[]
  stackName?: string
  templateVersion?: string
  accountName?: string
}

interface AsideAccountContentProps {
  account: Account
  close: () => void
}

export function AsideEditAccountContent({ account, close }: AsideAccountContentProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: updateAccount } = useUpdateAccount()

  const form = useForm()

  useEffect(() => {
    const values = {
      ...account,
      accountName: account.accountName || '',
      regions: account.regions || [],
      curRoleArn: account.curRoleArn || '',
      curRoleExternalId: account.curRoleExternalId || '',
      // member account
      managementAccountId: account.managementAccountId || '',
      monitoringRoleArn: account.monitoringRoleArn || '',
      monitoringRoleExternalId: account.monitoringRoleExternalId || '',
      monitoringRoleName: account.monitoringRoleName || '',

      // management account
      orgRoleArn: account.orgRoleArn || '',
      orgRoleExternalId: account.orgRoleExternalId || '',
      orgRoleName: account.orgRoleName || '',
    }
    form.setValues(values)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  async function onSubmit(partialAccount: PartialAccount) {
    setIsLoading(true)
    await updateAccount(partialAccount)
    close()
    form.reset()
    setIsLoading(false)
  }

  return (
    <>
      <Flex>
        <Text c="dark.7" lh="24px" size="1rem" fw={700}>
          {t('account:fields.account-id')}
        </Text>
        <Text ml={10} c="dark.3" lh="24px" size="1rem" fw={700}>
          {account.id}
        </Text>
      </Flex>

      <Text mt={20} c="dark.7" lh="24px" size="1rem" fw={700}>
        {t('account:fields.stack')}
      </Text>
      <Text mt={5} c="dark.5" lh="20px" size=".8rem" fw={500}>
        {account.stackName}
      </Text>

      <Divider mt={12} mb={12} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text c="dark.7" lh="24px" size="1rem" fw={700}>
          {t('account:edit.description')}
        </Text>
        <Box mt={10}>
          <TextInput w="100%" label={t('account:fields.account-name')} {...form.getInputProps('accountName')} />

          <MultiSelect
            mt={10}
            w="100%"
            withAsterisk
            label={t('account:fields.regions')}
            data={defaultRegions.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            {...form.getInputProps('regions')}
          />

          <TextInput
            mt={10}
            withAsterisk
            w="100%"
            label={t('account:fields.template-version')}
            {...form.getInputProps('templateVersion')}
          />
          <TextInput mt={10} withAsterisk w="100%" label={t('account:fields.stack-id')} {...form.getInputProps('stackId')} />
          <TextInput mt={10} withAsterisk w="100%" label={t('account:fields.org-role-arn')} {...form.getInputProps('orgRoleArn')} />
          <TextInput mt={10} w="100%" label={t('account:fields.org-external-id')} {...form.getInputProps('orgRoleExternalId')} />
          <TextInput mt={10} withAsterisk w="100%" label={t('account:fields.org-role-name')} {...form.getInputProps('orgRoleName')} />
          <TextInput mt={10} withAsterisk w="100%" label={t('account:fields.cur-role-arn')} {...form.getInputProps('curRoleArn')} />
          <TextInput mt={10} w="100%" label={t('account:fields.cur-external-id')} {...form.getInputProps('curRoleExternalId')} />
          <TextInput mt={10} w="100%" label={t('account:fields.management-account-id')} {...form.getInputProps('managementAccountId')} />
          <TextInput mt={10} w="100%" label={t('account:fields.monitoring-role-arn')} {...form.getInputProps('monitoringRoleArn')} />
          <TextInput
            mt={10}
            w="100%"
            label={t('account:fields.monitoring-role-external-id')}
            {...form.getInputProps('monitoringRoleExternalId')}
          />
          <TextInput mt={10} w="100%" label={t('account:fields.monitoring-role-name')} {...form.getInputProps('monitoringRoleName')} />
        </Box>
        <Group mt={20} justify="flex-end">
          <Button loading={isLoading} type="submit" size="xs">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </>
  )
}
