import { ActionIcon, Anchor, Breadcrumbs, Flex, Pill, PillGroup, Text, Title, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconArrowLeft, IconPlus } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { Account } from 'api/domain/entities/account'
import { defaultRegions } from 'api/dto/region'
import { useListAccounts } from 'api/query/account'
import { Aside } from 'components/aside/aside'
import { AsideEditAccountContent } from 'components/aside/content/aside-edit-account-content'
import { AddAccountDrawer } from 'components/drawer/add.account'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export function Accounts() {
  const { id: tenantId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [opened, { close, open }] = useDisclosure()
  const { data: accounts, isLoading: isLoadingAccounts } = useListAccounts(tenantId)
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>()

  const columns: ColumnDef<Account>[] = [
    {
      id: 'account-id',
      accessorKey: 'id',
      header: t('account:columns.account-id'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'account-name',
      accessorKey: 'accountName',
      header: t('account:columns.account-name'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'regions',
      accessorKey: 'regions',
      header: t('account:columns.regions'),
      cell: (col) => {
        const regions = defaultRegions.filter((r) => (col.getValue() as string[]).includes(r.id)).map((r) => r.name)

        if (regions.length > 2) {
          const split = regions.slice(0, 2)
          return (
            <PillGroup>
              {split.map((r) => (
                <Pill key={r} size="sm">
                  {r}
                </Pill>
              ))}
              <Tooltip label={regions.slice(2)} position="left" withArrow>
                <Pill size="sm">+{regions.length - 2}</Pill>
              </Tooltip>
            </PillGroup>
          )
        }

        return (
          <PillGroup>
            {regions.map((r) => (
              <Pill key={r} size="sm">
                {r}
              </Pill>
            ))}
          </PillGroup>
        )
      },
    },
    {
      id: 'stack',
      accessorKey: 'stackName',
      header: t('account:columns.stack'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'template',
      accessorKey: 'templateVersion',
      header: t('account:columns.template'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
  ]

  if (isLoadingAccounts) {
    return <Loading size="xl" />
  }

  return (
    <>
      <Table<Account>
        title={
          <Flex align="center">
            <ActionIcon size="sm" variant="subtle" radius="xl" onClick={() => navigate('/admin/tenants')}>
              <IconArrowLeft />
            </ActionIcon>
            <Text ml={10} size="1.375rem" fw={700}>
              {t('pages.accounts')}
            </Text>
            <Text size="1.375rem" c="gray.6" ml={5} fw={700}>
              {`(${accounts?.length})`}
            </Text>
          </Flex>
        }
        columns={columns}
        data={accounts || []}
        actionButtons={() => [
          <ActionIcon onClick={open} size="md" variant="subtle">
            <IconPlus size={20} />
          </ActionIcon>,
        ]}
        onRowClick={(r) => setSelectedAccount(r.original)}
      >
        <Aside close={() => setSelectedAccount(undefined)} opened={!!selectedAccount}>
          {selectedAccount && <AsideEditAccountContent account={selectedAccount} close={() => setSelectedAccount(undefined)} />}
        </Aside>
      </Table>
      <AddAccountDrawer opened={opened} close={close} />
    </>
  )
}
