import { Box, Button, Drawer, Group, MultiSelect, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { defaultRegions } from 'api/dto/region'
import { useCreateAccount } from 'api/query/account'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface AddAccountProps {
  opened: boolean
  close: () => void
}

type PartialAccount = {
  id: string

  accountName?: string

  // cur account
  curRoleArn?: string
  curRoleExternalId?: string

  // member account
  managementAccountId?: string
  monitoringRoleArn?: string
  monitoringRoleExternalId?: string
  monitoringRoleName?: string

  // management account
  orgRoleArn?: string
  orgRoleExternalId?: string
  orgRoleName?: string

  regions: string[]
  stackId: string
  stackName: string
  templateVersion: string
}

export function AddAccountDrawer({ opened, close }: AddAccountProps) {
  const { t } = useTranslation()
  const { id } = useParams()
  const { mutateAsync: createAccount } = useCreateAccount()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<PartialAccount>({
    initialValues: {
      id: '',
      regions: ['us-east-1'],
      stackId: '',
      stackName: '',
      templateVersion: '',
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.id || values.id.length < 1) {
        errors.id = t('common.required')
      }

      if (!values.regions || values.regions.length < 1) {
        errors.regions = t('common.required')
      }

      if (!values.stackName || values.stackName.length < 1) {
        errors.stackName = t('common.required')
      }

      if (!values.templateVersion || values.templateVersion.length < 1) {
        errors.templateVersion = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialAccount: PartialAccount) {
    setIsLoading(true)
    await createAccount({ ...partialAccount, tenantId: id })
    close()
    form.reset()
    setIsLoading(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('account:add.title')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text c="dark.7" lh="24px" size="1rem" fw={700}>
                {t('account:add.description')}
              </Text>
              <Box mt={10}>
                <TextInput withAsterisk w="100%" label={t('account:fields.account-id')} {...form.getInputProps('id')} />
                <TextInput w="100%" label={t('account:fields.account-name')} {...form.getInputProps('accountName')} />
                <MultiSelect
                  mt={10}
                  w="100%"
                  label={t('account:fields.regions')}
                  data={defaultRegions.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  {...form.getInputProps('regions')}
                />
                <TextInput mt={10} withAsterisk w="100%" label={t('account:fields.stack')} {...form.getInputProps('stackName')} />
                <TextInput
                  mt={10}
                  withAsterisk
                  w="100%"
                  label={t('account:fields.template-version')}
                  {...form.getInputProps('templateVersion')}
                />

                <TextInput mt={10} w="100%" label={t('account:fields.org-role-arn')} {...form.getInputProps('orgRoleArn')} />
                <TextInput mt={10} w="100%" label={t('account:fields.org-external-id')} {...form.getInputProps('orgRoleExternalId')} />

                <TextInput w="100%" label={t('account:fields.org-role-name')} {...form.getInputProps('orgRoleName')} />
                <TextInput mt={10} w="100%" label={t('account:fields.cur-role-arn')} {...form.getInputProps('curRoleArn')} />

                <TextInput mt={10} w="100%" label={t('account:fields.cur-external-id')} {...form.getInputProps('curRoleExternalId')} />
                <TextInput
                  mt={10}
                  w="100%"
                  label={t('account:fields.management-account-id')}
                  {...form.getInputProps('managementAccountId')}
                />

                <TextInput mt={10} w="100%" label={t('account:fields.monitoring-role-arn')} {...form.getInputProps('monitoringRoleArn')} />

                <TextInput
                  mt={10}
                  w="100%"
                  label={t('account:fields.monitoring-role-external-id')}
                  {...form.getInputProps('monitoringRoleExternalId')}
                />

                <TextInput
                  mt={10}
                  w="100%"
                  label={t('account:fields.monitoring-role-name')}
                  {...form.getInputProps('monitoringRoleName')}
                />
              </Box>
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button loading={isLoading} type="submit" size="xs">
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
