import { Provider, TenantDto, TenantStatus } from 'api/dto/tenant'

export class Tenant {
  id?: string
  name: string
  provider: Provider
  status: TenantStatus
  accountIds?: string[]

  constructor(tenant: TenantDto) {
    this.id = tenant.id
    this.name = tenant.name
    this.provider = tenant.provider
    this.status = tenant.status
  }

  needsOnboarding() {
    return this.status === TenantStatus.ONBOARDING_STEP_1 || this.status === TenantStatus.ONBOARDING_STEP_2
  }

  isOnboardingStep(step: TenantStatus) {
    return this.status === step
  }
}
