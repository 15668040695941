import { ActionIcon, Box } from '@mantine/core'
import { CSSProperties } from 'react'
import classes from './aside.module.scss'
import { IconX } from '@tabler/icons-react'

interface AsideProps {
  opened: boolean
  close: () => void
  children: React.ReactNode
}

export function Aside({ opened, close, children }: AsideProps) {
  const styles: CSSProperties = opened
    ? {
        width: '24.25rem',
        marginLeft: '1.25rem',
        marginTop: '1.4375rem',
        borderRadius: '1rem',
      }
    : {
        width: 0,
        overflow: 'hidden',
        marginTop: '1.4375rem',
        borderRadius: '1rem',
      }

  return (
    <Box className={classes.aside} style={styles}>
      <Box className={classes.content}>
        <ActionIcon className={classes.close} variant="transparent" onClick={close} title="Close" aria-label="Close" size="sm" color="gray">
          <IconX />
        </ActionIcon>

        {children}
      </Box>
    </Box>
  )
}
