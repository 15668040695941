/* eslint-disable max-len */

export const HelpIcon = ({ height, width }: { width?: string; height?: string }) => {
  return (
    <svg width={width || 18} height={height || 18} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 14V14.01M10 11C10.4483 10.9986 10.8832 10.8468 11.235 10.569C11.5868 10.2911 11.8352 9.90329 11.9406 9.46753C12.0459 9.03178 12.0019 8.57329 11.8158 8.16545C11.6297 7.75761 11.3122 7.424 10.914 7.218C10.5162 7.01421 10.0611 6.95102 9.62281 7.03872C9.18451 7.12642 8.78879 7.35983 8.5 7.701M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
