import { TextInput, Button, Text, Box, Anchor, PasswordInput } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { NS } from 'i18n'

interface FormValues {
  email: string
  password: string
}

export const Login = () => {
  const { login } = useAuth()
  const { t } = useTranslation(NS.session)
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    const { email, password } = values
    setIsLoading(true)
    await login(email, password)
    setIsLoading(false)
  }

  return (
    <Box display="flex" p="0 110px 39px" style={{ flex: 1, flexFlow: 'column', padding: '20px 30px', zIndex: 100 }}>
      <Box mt={183} mb={40} display="flex" w="100%" style={{ justifyContent: 'center', flexDirection: 'column' }}>
        <Text mb={12} fw={700} size="40px">
          {t('login.welcome')}
        </Text>
        <Text fw={400} lh="1.375rem">
          {t('login.please-login')}
        </Text>
      </Box>

      <LoginForm form={form} isLoading={isLoading} onSubmit={onSubmit} />

      <Box flex={0}>
        <Text c="#909296" ta="center" size=".875rem">
          {t('login.dont-have-account')}{' '}
          <Anchor href="/session/register" size=".875rem" c="#2c2e33">
            {t('login.register-here')}
          </Anchor>
        </Text>
      </Box>
    </Box>
  )
}

interface LoginFormProps {
  form: UseFormReturnType<
    {
      email: string
      password: string
    },
    (values: { email: string; password: string }) => {
      email: string
      password: string
    }
  >
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
}

function LoginForm({ form, isLoading, onSubmit }: LoginFormProps) {
  const navigate = useNavigate()
  const { t } = useTranslation(NS.session)

  return (
    <Box display="flex" w="100%" flex={1}>
      <form style={{ width: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
        <Box mb={10}>
          <TextInput w="100%" label={t('login.email') || ''} {...form.getInputProps('email')} />
        </Box>

        <Box mb={30}>
          <PasswordInput mb={5} w="100%" type="password" label={t('login.password') || ''} {...form.getInputProps('password')} />
        </Box>

        <Button w="100%" mb={8} color="#868E96" radius="sm" type="submit" loading={isLoading}>
          <Text size=".875rem">{t(`${NS.translation}:common.login`)}</Text>
        </Button>
        <Anchor size="sm" underline="hover" onClick={() => navigate('/session/forgot-password')}>
          {t('login.forgot-password')}
        </Anchor>
      </form>
    </Box>
  )
}
