import { AccountDto } from './account-dto'
import { Account } from 'api/domain/entities/account'

export class AccountFactory {
  static fromDto(accountDto: AccountDto): Account {
    return new Account(accountDto)
  }

  static toDto(account: Partial<Account>): Partial<AccountDto> {
    return {
      id: account.id,
      accountName: account.accountName,
      tenantId: account.tenantId,
      regions: account.regions,
      stackId: account.stackId,
      stackName: account.stackName,
      templateVersion: account.templateVersion,
      curRoleArn: account.curRoleArn,
      curRoleExternalId: account.curRoleExternalId,
      managementAccountId: account.managementAccountId,
      monitoringRoleArn: account.monitoringRoleArn,
      monitoringRoleExternalId: account.monitoringRoleExternalId,
      monitoringRoleName: account.monitoringRoleName,
      orgRoleArn: account.orgRoleArn,
      orgRoleExternalId: account.orgRoleExternalId,
      orgRoleName: account.orgRoleName,
    }
  }

  static toCreateUpdateDto(account: Partial<Account>): Partial<AccountDto> {
    return {
      id: account.id,
      accountName: account.accountName,
      tenantId: account.tenantId,
      regions: account.regions,
      stackId: account.stackId,
      stackName: account.stackName,
      templateVersion: account.templateVersion,
      curRoleArn: account.curRoleArn,
      curRoleExternalId: account.curRoleExternalId,
      managementAccountId: account.managementAccountId,
      monitoringRoleArn: account.monitoringRoleArn,
      monitoringRoleExternalId: account.monitoringRoleExternalId,
      monitoringRoleName: account.monitoringRoleName,
      orgRoleArn: account.orgRoleArn,
      orgRoleExternalId: account.orgRoleExternalId,
      orgRoleName: account.orgRoleName,
    }
  }
}
