import { useQuery } from '@tanstack/react-query'
import { RecommendationType } from 'api/domain/entities/recommendation'
import { CostOptimizationRecommendation } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { RecommendationAssembler } from 'api/dto/recommendation/recommendation-assembler'
import { CostOptimizationRecommendationDto, RecommendationDto } from 'api/dto/recommendation/recommendation-dto'
import axios from 'config/axios'
import { useCallback } from 'react'

export const useListRecommendations = (type: RecommendationType, tenantId?: string) =>
  useQuery({
    queryKey: ['recommendations', type, tenantId],
    queryFn: async () => {
      const { data } = await axios.get<RecommendationDto[]>(`/tenants/${tenantId}/recommendations`, {
        params: {
          type,
        },
      })
      return data
    },
    select: useCallback((recommendations: RecommendationDto[]) => {
      const assembled = recommendations.map((r) => {
        const assembler = RecommendationAssembler.create(r)
        return assembler ? assembler.fromDto(r as unknown as CostOptimizationRecommendationDto) : undefined
      })

      return assembled.filter((r) => r) as CostOptimizationRecommendation[]
    }, []),
    enabled: !!tenantId,
    staleTime: 1000 * 60 * 5,
  })

export const useGetRecommendation = (type: RecommendationType, tenantId?: string, recommendationId?: string) =>
  useQuery({
    queryKey: ['recommendation', type, recommendationId],
    queryFn: async () => {
      const { data } = await axios.get<RecommendationDto>(`/tenants/${tenantId}/recommendations/${recommendationId}`, {
        params: {
          type,
        },
      })
      return data
    },
    select: useCallback((recommendation: RecommendationDto) => {
      const assembler = RecommendationAssembler.create(recommendation)
      return assembler ? assembler.fromDto(recommendation as unknown as CostOptimizationRecommendationDto) : undefined
    }, []),
    enabled: !!tenantId && !!recommendationId,
    staleTime: 1000 * 60 * 5,
  })
