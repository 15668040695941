import { Box, Text, rem } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'

export function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
  return (
    <Text data-testid="password-requirement" c={meets ? 'teal' : 'red'} style={{ display: 'flex', alignItems: 'center' }} mt={7} size="sm">
      {meets ? (
        <IconCheck data-testid="check-icon" style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX data-testid="x-icon" style={{ width: rem(14), height: rem(14) }} />
      )}
      <Box ml={10}>{label}</Box>
    </Text>
  )
}
