import { BarChart, DonutChart } from '@mantine/charts'
import { Box, Flex, Text, Title } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { RecommendationType } from 'api/domain/entities/recommendation'
import { CostOptimizationResource } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { Resource } from 'api/domain/entities/resource/resource'
import { useListRecommendations } from 'api/query/recommendation'
import { useListResources } from 'api/query/resource'
import { useGetTenantMe } from 'api/query/tenant'
import { Donut } from 'components/chart/donut'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { Widget } from 'components/widget/widget'
import { useTranslation } from 'react-i18next'
import { generateBarChartData, generateSavingsData, Savings } from 'utils/chart'
import { formatCost } from 'utils/number'

export function Dashboard() {
  const { t } = useTranslation()
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenantMe()
  const { data: recommendations, isLoading: isLoadingRecommendations } = useListRecommendations(
    RecommendationType.COST_OPTIMIZATION,
    tenant?.id,
  )
  const { data: resources, isLoading: isLoadingResources } = useListResources(tenant?.id, 25)

  const potentialSavingsData = generateSavingsData(recommendations || [], Savings.POTENTIAL)
  const realizedSavingsData = generateSavingsData(recommendations || [], Savings.REALIZED)

  const columns: ColumnDef<Resource>[] = [
    {
      id: 'account',
      accessorKey: 'accountId',
      header: t('cost:columns.account'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'region',
      accessorKey: 'region',
      header: t('cost:columns.region'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'service',
      accessorKey: 'service',
      header: t('cost:columns.service'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'resourceName',
      accessorKey: 'resourceName',
      header: t('cost:columns.resource'),
      cell: (col) => {
        return (
          <Text size="sm" maw={350} lineClamp={2}>
            {col.getValue() as string}
          </Text>
        )
      },
    },
    {
      id: 'resourceId',
      accessorKey: 'resourceId',
      header: t('cost:columns.resource-id'),
      cell: (col) => {
        return (
          <Text size="sm" maw={350} lineClamp={2}>
            {col.getValue() as string}
          </Text>
        )
      },
    },
    {
      id: 'last7DaysCost',
      accessorKey: 'last7DaysCost',
      header: t('cost:columns.last-7-days'),
      cell: (col) => {
        const value = col.getValue() as number

        return <Text size="sm">{formatCost(value)}</Text>
      },
    },
    {
      id: 'last31DaysCost',
      accessorKey: 'last31DaysCost',
      header: t('cost:columns.last-31-days'),
      cell: (col) => {
        const value = col.getValue() as number
        return <Text size="sm">{formatCost(value)}</Text>
      },
    },
    {
      id: 'annualizedCost',
      accessorKey: 'annualizedCost',
      header: t('cost:columns.annualized-cost'),
      cell: (col) => {
        const value = col.getValue() as number
        return <Text size="sm">{formatCost(value)}</Text>
      },
    },
  ]

  if (isLoadingTenant || isLoadingResources || isLoadingRecommendations) {
    return <Loading size="xl" />
  }

  return (
    <>
      <Title order={1}>{t('pages.dashboard').toUpperCase()}</Title>

      <Box mt={46}>
        <Flex gap={20} h={340} display="flex">
          <Widget>
            <Text mb="lg" pl="sm">
              {t('cost:potential-savings-by-difficulty')}
            </Text>
            <Box mt={50} display="flex" sx={{ justifyContent: 'center' }}>
              <Donut data={potentialSavingsData.byDifficulty} />
            </Box>
          </Widget>
          <Widget>
            <Text mb="lg" pl="sm">
              {t('cost:realized-savings-by-difficulty')}
            </Text>
            <Box mt={50} display="flex" sx={{ justifyContent: 'center' }}>
              <Donut data={realizedSavingsData.total} />
            </Box>
          </Widget>
        </Flex>

        <Table<Resource>
          title={
            <Text size="1.375rem" fw={700}>
              {t('cost:topResources')}
            </Text>
          }
          defaultSort={{ id: 'last31DaysCost', desc: true }}
          columns={columns}
          data={resources || []}
        ></Table>
      </Box>
    </>
  )
}
