/* eslint-disable @typescript-eslint/unbound-method */
import { AppShell, Box, Center } from '@mantine/core'
import { AdminLinks } from 'components/links/admin'
import { MainLinks } from 'components/links/main-links'
import { Logo } from 'components/logo'
import { User } from 'components/user/user'
import { useAuth } from 'hooks/useAuth'
import { Outlet } from 'react-router'

export default function AppLayout() {
  const { user } = useAuth()

  return (
    <AppShell
      padding="md"
      navbar={{
        breakpoint: 'xs',
        width: 300,
      }}
    >
      <AppShell.Navbar bg="custom-dark.0">
        <AppShell.Section mt="40px">
          <Center>
            <Logo width={125} height={70} />
          </Center>
        </AppShell.Section>
        <AppShell.Section grow mt="44px">
          <MainLinks />
        </AppShell.Section>
        {user?.isAdmin && (
          <AppShell.Section mb={8}>
            <AdminLinks />
          </AppShell.Section>
        )}
      </AppShell.Navbar>

      <AppShell.Main bg="#f6f6f8">
        <Box p="3px 8px">
          <User />
          <Outlet />
        </Box>
      </AppShell.Main>
    </AppShell>
  )
}
