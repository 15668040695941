import { Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

export function Admin() {
  const { t } = useTranslation()
  return (
    <>
      <Title>{t('pages.admin').toUpperCase()}</Title>
      <Outlet />
    </>
  )
}
