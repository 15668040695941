import { IconLogout } from '@tabler/icons-react'
import { Avatar, Text, Box, useMantineColorScheme, Menu, Flex } from '@mantine/core'
import { useAuth } from 'hooks/useAuth'
import { useGetUserMe, useSwitchTenant } from 'api/query/user'
import { useGetTenantMe } from 'api/query/tenant'
import ChevronIcon from 'assets/icons/chevron-down.svg?react'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@mantine/hooks'
import { UserProfileModal } from './user-profile.modal'

export function User() {
  const { t } = useTranslation()
  const { data: user } = useGetUserMe()
  const { data: tenant } = useGetTenantMe()
  const { mutateAsync: switchTenant } = useSwitchTenant()
  const { logout } = useAuth()
  const [opened, { close, open }] = useDisclosure()

  const tenants = [...(user?.tenants || [])].sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <Flex align="center" pos="absolute" top={20} right={20}>
      <Menu position="bottom-start">
        <Menu.Target>
          <Flex
            justify="space-between"
            align="center"
            w={273}
            style={{
              cursor: 'pointer',
            }}
          >
            <Flex>
              <Flex align="flex-end">
                <Avatar size={38} radius="xl">
                  {user?.initials}
                </Avatar>
              </Flex>

              <Flex direction="column" justify="flex-end" ml={12}>
                <Text fw={700} size="1rem" lh="24px">
                  {user?.fullName}
                </Text>
                <Text truncate maw={220} size=".75rem" lh="18px" c="dark.2" tt="uppercase">
                  {tenant?.name}
                </Text>
              </Flex>
            </Flex>
            <Flex pr={20} align="center">
              <ChevronIcon />
            </Flex>
          </Flex>
        </Menu.Target>

        <Menu.Dropdown w={273}>
          <Menu.Label>
            <Flex align="center">
              <Avatar size="2rem">{user?.initials}</Avatar>
              <Box ml={10}>
                <Text fw={700} size="sm">
                  {user?.fullName}
                </Text>
                <Text truncate maw={210} size="xs">
                  {user?.email}
                </Text>
              </Box>
            </Flex>
          </Menu.Label>
          <Menu.Divider />
          {tenants && tenants.length > 0 && (
            <>
              <Menu.Label>{t('common.tenants')}</Menu.Label>
              {tenants.map((tenant) => (
                <Menu.Item key={tenant.id} onClick={() => switchTenant(tenant.id)}>
                  {tenant.name}
                </Menu.Item>
              ))}
              <Menu.Divider />
            </>
          )}
          <Menu.Label>{t('common.settings')}</Menu.Label>
          <Menu.Item onClick={open}>{t('common.profile')}</Menu.Item>

          <Menu.Item leftSection={<IconLogout />} onClick={() => logout()}>
            {t('common.logout')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <UserProfileModal isOpen={opened} onClose={close} />
    </Flex>
  )
}
