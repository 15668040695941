import { CostOptimizationRecommendationType } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { formatString } from './number'

interface AdditionalColumn {
  key: string
  parser?: (value: any) => string
}

const additionalColumns: Record<CostOptimizationRecommendationType, AdditionalColumn[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [
    { key: 'dbEngine' },
    { key: 'dbEngineMajorVersion' },
    { key: 'estimatedDbVcpu', parser: (value) => formatString(value, { suffix: ' vCPU' }) },
  ],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [
    { key: 'estimatedGbMonths', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'totalEstimatedGbMonthsIntelligentTiering', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
}

export function getAdditionalColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return additionalColumns[costOptimizationRecommendationType] || []
}
