import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs'
import 'dayjs/locale/fr'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)

dayjs.locale('fr')

export enum DateTimeFormat {
  DayMonthAndYear = 'DD-MM-YYYY',
  YearMonthAndDay = 'YYYY-MM-DD',
  YearMonthAndDayWithTime = 'YYYY-MM-DD HH:mm:ss',
}

export class DateTime {
  public static fromDate = (date: Date): DateTime => {
    return new DateTime(dayjs(date))
  }

  public static fromISOString = (isoString: string): DateTime => {
    return new DateTime(dayjs(isoString))
  }

  public static fromISOStringOrDate = (value: any): DateTime => {
    if (value instanceof Date) {
      return DateTime.fromDate(value)
    }

    if (typeof value === 'string') {
      return DateTime.fromISOString(value)
    }

    throw new Error(`Value is not a Date or ISO8601 string date: ${value}`)
  }

  public static now = (): DateTime => {
    return new DateTime(dayjs())
  }

  public static startOfTime = (): DateTime => new DateTime(dayjs(0))

  public addDays = (value: number): DateTime => {
    return new DateTime(this.date.add(value, 'days'))
  }

  public addHours = (value: number): DateTime => {
    return new DateTime(this.date.add(value, 'hours'))
  }

  public addMinutes = (value: number): DateTime => {
    return new DateTime(this.date.add(value, 'minutes'))
  }

  public addSeconds = (value: number): DateTime => {
    return new DateTime(this.date.add(value, 'seconds'))
  }

  date: Dayjs

  public daysBetween = (dateTime: DateTime): number => {
    return Math.floor(this.diff(dateTime, 'days'))
  }

  public diff = (dateTime: DateTime, unit: ManipulateType): number => {
    return this.date.diff(dateTime.date, unit, true)
  }

  public endOf = (unit: OpUnitType): DateTime => new DateTime(this.date.endOf(unit))

  public getDay = (): number => {
    return this.date.day()
  }

  public getFirstWorkingDayOfMonth = (): DateTime => {
    let firstWorkingDay = this.startOf('month')
    if (firstWorkingDay.getDay() === 0 || firstWorkingDay.getDay() === 6) {
      firstWorkingDay = firstWorkingDay.getNextMonday()
    }
    return firstWorkingDay
  }

  public getNextMonday = (): DateTime => {
    const day = this.getDay()
    if (day > 1) {
      this.date = this.date.add(1, 'week')
    }
    return this.setDay(1)
  }

  public getYear = (): number => {
    return this.date.year()
  }
  public isAfter = (other: DateTime): boolean => {
    return this.date.isAfter(other.date)
  }

  public isBefore = (other: DateTime): boolean => {
    return this.date.isBefore(other.date)
  }

  public isFirstWorkingDayOfMonth = (): boolean => {
    return this.getFirstWorkingDayOfMonth().toFormat(DateTimeFormat.YearMonthAndDay) === this.toFormat(DateTimeFormat.YearMonthAndDay)
  }

  public isMonday = (): boolean => this.date.day() === 1

  public isSame = (other: DateTime): boolean => {
    return this.date.isSame(other.date)
  }

  public isSameOrAfter = (other: DateTime): boolean => {
    return this.date.isSameOrAfter(other.date)
  }

  public isSameOrBefore = (other: DateTime): boolean => {
    return this.date.isSameOrBefore(other.date)
  }

  public setDay = (numberDay: number): DateTime => {
    return new DateTime(this.date.day(numberDay))
  }

  public setHour = (hour: number): DateTime => new DateTime(this.date.hour(hour))

  public startOf = (unit: OpUnitType): DateTime => new DateTime(this.date.startOf(unit))

  public subtract = (number: number, unit: ManipulateType): DateTime => new DateTime(this.date.subtract(number, unit))

  public toFormat = (format: DateTimeFormat): string => this.date.format(format)

  public toISOString = (): string => {
    return this.date.toISOString()
  }

  public toJsDate = (): Date => {
    return this.date.toDate()
  }

  constructor(date: Dayjs) {
    this.date = date.utc()
  }
}
