/* eslint-disable @typescript-eslint/naming-convention */
import type { MantineThemeOverride } from '@mantine/core'

export const theme: MantineThemeOverride = {
  primaryColor: 'gray',
  fontFamily: 'Lato',
  colors: {
    'custom-magenta': ['#F93052', '#F93052', '#F93052', '#F93052', '#F93052', '#F93052', '#F93052', '#F93052', '#F93052', '#F93052'],
    'custom-gray': [
      '#F1F1F1', //
      '#F8F9FA',
      '#909296',
      '#2c2e33',
      '#00ffff',
      '#ffff00',
      '#868E96',
      '#454545', // Neutral/Grayscale/700
      '#CBCBCB',
      '#000000',
      '#5C5F66',
      '#373A40',
    ],
    'custom-dark': [
      '#25262B',
      // Just to fill as we need 10 colors
      '#25262B',
      '#25262B',
      '#25262B',
      '#25262B',
      '#25262B',
      '#25262B',
      '#25262B',
      '#25262B',
      '#25262B',
    ],
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
}
