import { TenantDto } from './tenant-dto'
import { Tenant } from 'api/domain/entities/tenant'

export class TenantFactory {
  static fromDto(tenantDto: TenantDto): Tenant {
    return new Tenant(tenantDto)
  }

  static toDto(tenant: Partial<Tenant>): Partial<TenantDto> {
    return {
      id: tenant.id,
      name: tenant.name,
      provider: tenant.provider,
      status: tenant.status,
    }
  }

  static toUpdateDto(tenant: Partial<Tenant>): Partial<TenantDto> {
    return {
      name: tenant.name,
      provider: tenant.provider,
      status: tenant.status,
    }
  }
}
