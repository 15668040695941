import { AppShell, Box, Image, Text, useMantineTheme } from '@mantine/core'
import { Outlet, useOutletContext } from 'react-router-dom'
import classes from './onboarding.layout.module.scss'
import { Dispatch, SetStateAction, useState } from 'react'
import { HelpBlock } from 'components/onboarding/aside/help-block'
import { OnboardingStepper } from 'components/onboarding/aside/onboarding-stepper'
import { useTranslation } from 'react-i18next'
import { NS } from 'i18n'
import Logo from '../assets/images/unimoni-logo.png'
import { OnboardingSteps } from 'utils/steps'
import { ProvideOnboarding } from 'hooks/useOnboarding'
import { useAuth } from 'hooks/useAuth'

export interface OnboardingContext {
  step: number
  setStep: Dispatch<SetStateAction<number>>
}

export function OnboardingLayout() {
  const { logout } = useAuth()
  const [step, setStep] = useState(OnboardingSteps.Init)
  const theme = useMantineTheme()
  const { t } = useTranslation()

  return (
    <AppShell padding="md" navbar={{ width: 470, breakpoint: 'sm' }}>
      <AppShell.Navbar p="40px 36px" className={classes.aside} bg={theme.colors['custom-dark'][0]} c="white">
        <Box className={classes.asideContent}>
          <Box className={classes['aside-header']}>
            <Image src={Logo} alt="Unimoni" className={classes['aside-header-logo']} w={76} h={44} />
          </Box>
          <Text fw={700} size="28px" lh="2.25rem" mb={20}>
            {t(`${NS.onboarding}:sidebar.title`)}
          </Text>

          <OnboardingStepper activeStep={step} />
          <Box className={classes['aside-footer']}>
            <HelpBlock />
          </Box>
        </Box>
      </AppShell.Navbar>

      <AppShell.Main bg="#F1F1F1">
        <Box ta="right">
          <Text fz={14} fw={700} c={theme.colors['custom-gray'][2]} style={{ cursor: 'pointer' }} onClick={logout}>
            {t(`${NS.onboarding}:header.quitAndContinueLater`)}
          </Text>
        </Box>
        <Box p="17px 46px">
          <ProvideOnboarding>
            <Outlet context={{ step, setStep } satisfies OnboardingContext} />
          </ProvideOnboarding>
        </Box>
      </AppShell.Main>
    </AppShell>
  )
}

export const useOnboardingContext = () => {
  return useOutletContext<OnboardingContext>()
}
