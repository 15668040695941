export interface RegionDto {
  id: string
  name: string
}

export const defaultRegions: RegionDto[] = [
  {
    id: 'us-east-1',
    name: 'US East (N. Virginia)',
  },
  {
    id: 'ca-central-1',
    name: 'Canada (Central)',
  },
  {
    id: 'eu-central-1',
    name: 'EU (Frankfurt)',
  },
  {
    id: 'eu-west-1',
    name: 'EU (Ireland)',
  },
  {
    id: 'eu-west-2',
    name: 'EU (London)',
  },
  {
    id: 'eu-west-3',
    name: 'EU (Paris)',
  },
  {
    id: 'eu-north-1',
    name: 'EU (Stockholm)',
  },
  {
    id: 'us-east-2',
    name: 'US East (Ohio)',
  },
  {
    id: 'us-west-1',
    name: 'US West (N. California)',
  },
  {
    id: 'us-west-2',
    name: 'US West (Oregon)',
  },
]
