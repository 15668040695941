import { RequireOnboarding } from 'components/hoc/RequireOnboarding'
import { ProvideAuth } from 'hooks/useAuth'
import AppLayout from 'layout/app.layout'
import { AuthLayout } from 'layout/auth.layout'
import { IndexLayout } from 'layout/index.layout'
import { OnboardingLayout } from 'layout/onboarding.layout'
import { Admin } from 'pages/admin/admin'
import { Accounts } from 'pages/admin/tenant/accounts'
import { Users } from 'pages/admin/tenant/users'
import { Tenants } from 'pages/admin/tenants'
import { Dashboard } from 'pages/dashboard'
import { CompletedOnboarding } from 'pages/onboarding/completed-onboarding'
import { Setup } from 'pages/onboarding/setup'
import { CostOptimizationRecommendation } from 'pages/recommendation/cost-optimization-recommendation'
import { CostOptimizationRecommendations } from 'pages/recommendation/cost-optimization-recommendations'
import { RequireAuthentication } from 'pages/require-authentication'
import { Activation } from 'pages/session/activation'
import { ForgotPassword } from 'pages/session/forgot-password'
import { Login } from 'pages/session/login'
import { Register } from 'pages/session/register'
import { ResetPassword } from 'pages/session/reset-password'
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

export function App() {
  return <RouterProvider router={router} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProvideAuth />}>
      <Route element={<IndexLayout />}>
        <Route path="session" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="activation" element={<Activation />} />
          <Route index element={<Navigate to="/session/login" />} />
        </Route>

        <Route
          path="onboarding"
          element={
            <RequireAuthentication>
              <OnboardingLayout />
            </RequireAuthentication>
          }
        >
          <Route path="setup" element={<Setup />} />
          <Route path="completed" element={<CompletedOnboarding />} />
          <Route index element={<Navigate to="/onboarding/setup" replace />} />
        </Route>

        <Route
          element={
            <RequireAuthentication>
              <RequireOnboarding>
                <AppLayout />
              </RequireOnboarding>
            </RequireAuthentication>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="cost-recommendations" element={<CostOptimizationRecommendations />} />
          <Route path="cost-recommendations/:id" element={<CostOptimizationRecommendation />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />

          <Route
            path="admin"
            element={
              <RequireAuthentication needsAdminRight>
                <Admin />
              </RequireAuthentication>
            }
          >
            <Route path="tenants" element={<Tenants />} />
            <Route path="tenants/:id/accounts" element={<Accounts />} />
            <Route path="tenants/:id/users" element={<Users />} />
            <Route index element={<Navigate to="/admin/tenants" />} />
            <Route path="*" element={<Navigate to="/admin/tenants" />} />
          </Route>
        </Route>

        <Route index element={<Navigate to="/dashboard" />} />
      </Route>
    </Route>,
  ),
)
