import { Box, Text, Title, useMantineTheme } from '@mantine/core'
import styles from './page-loading-animation.module.scss'
import { useTranslation } from 'react-i18next'
import { NS } from 'i18n'

interface Props {
  rendered?: boolean
  loadingText?: string
}
export const PageLoadingAnimation = ({ rendered = true, loadingText }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  if (!rendered) {
    return
  }

  return (
    <>
      <Box className={styles['loading-container']}>
        <Box className={styles['dot-flashing']}></Box>
        <Text fz={'24px'} fw={400} c={theme.colors['custom-gray'][10]} ta="center" mt={'20px'}>
          {loadingText || t('common.defaultLoadingText')}
        </Text>
      </Box>
    </>
  )
}
