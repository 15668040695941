import { AccessType } from './access'

export interface ITokenUserDto {
  id?: string
  email: string
  accessType: AccessType
  tenantName: string
  currentTenantId: string
}

export class UserToken implements ITokenUserDto {
  id?: string
  email: string
  accessType: AccessType
  tenantName: string
  currentTenantId: string

  constructor(userToken: ITokenUserDto) {
    this.id = userToken.id
    this.email = userToken.email
    this.accessType = userToken.accessType
    this.tenantName = userToken.tenantName
    this.currentTenantId = userToken.currentTenantId
  }

  get isAdmin() {
    return this.accessType === AccessType.ADMIN
  }
}
