import { AccountDto } from 'api/dto/account/account-dto'

export class Account {
  id?: string
  curRoleArn?: string
  curRoleExternalId?: string

  accountName?: string

  // member account
  managementAccountId?: string
  monitoringRoleArn?: string
  monitoringRoleExternalId?: string
  monitoringRoleName?: string

  // management account
  orgRoleArn?: string
  orgRoleExternalId?: string
  orgRoleName?: string

  regions: string[]
  stackId: string
  stackName: string
  templateVersion: string
  tenantId: string

  constructor(account: AccountDto) {
    this.id = account.id
    this.accountName = account.accountName
    this.curRoleArn = account.curRoleArn
    this.curRoleExternalId = account.curRoleExternalId
    this.managementAccountId = account.managementAccountId
    this.monitoringRoleArn = account.monitoringRoleArn
    this.monitoringRoleExternalId = account.monitoringRoleExternalId
    this.monitoringRoleName = account.monitoringRoleName
    this.orgRoleArn = account.orgRoleArn
    this.orgRoleExternalId = account.orgRoleExternalId
    this.orgRoleName = account.orgRoleName
    this.regions = account.regions
    this.stackId = account.stackId
    this.stackName = account.stackName
    this.templateVersion = account.templateVersion
    this.tenantId = account.tenantId
  }
}
