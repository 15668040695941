import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { Tenant } from 'api/domain/entities/tenant'
import { TenantDto } from 'api/dto/tenant'
import { TenantFactory } from 'api/dto/tenant/tenant-factory'
import { UserDto, UserFactory } from 'api/dto/user'
import axios from 'config/axios'
import { useCallback } from 'react'

export const useGetTenant = (id?: string) => {
  return useQuery({
    queryKey: ['tenants', id],
    queryFn: async () => {
      const { data } = await axios.get<TenantDto>(`/tenants/${id}`)
      return data
    },
    select: useCallback((tenant: TenantDto) => new Tenant(tenant), []),
    staleTime: 1000 * 60 * 2,
  })
}

export const useGetTenantMe = (enabled = true) => {
  return useQuery({
    queryKey: ['tenants', 'me'],
    queryFn: async () => {
      const { data } = await axios.get<TenantDto>('/tenants/me')
      return data
    },
    select: useCallback((tenant: TenantDto) => new Tenant(tenant), []),
    staleTime: 1000 * 60 * 2,
    enabled,
  })
}

export const useListTenants = () => {
  return useQuery({
    queryKey: ['tenants'],
    queryFn: async () => {
      const { data } = await axios.get<TenantDto[]>('/tenants')
      return data
    },
    select: useCallback((tenants: TenantDto[]) => tenants.map((t) => new Tenant(t)), []),
    staleTime: 1000 * 60 * 2,
  })
}

export const useCreateTenant = () => {
  return useMutation({
    mutationFn: async (tenant: Partial<TenantDto>) => {
      const createDto = TenantFactory.toDto(tenant)
      const { data } = await axios.post<TenantDto>('/tenants', createDto)
      return data
    },
    onSuccess: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants'],
      })
    },
  })
}

export const useUpdateTenant = () => {
  return useMutation({
    mutationFn: async (tenant: Partial<TenantDto>) => {
      const updateDto = TenantFactory.toUpdateDto(tenant)
      const { data } = await axios.put<TenantDto>(`/tenants/${tenant.id}`, updateDto)
      return data
    },
    onSuccess: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants'],
      })
    },
  })
}

export const useCreateUserTenant = (tenantId?: string) => {
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const createUserDto = UserFactory.toCreateUpdateDto(user)
      const { data } = await axios.post<UserDto>(`/tenants/${tenantId}/users`, createUserDto)
      return data
    },
    onSuccess: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants', tenantId, 'users'],
      })
    },
  })
}

export const useUpdateUserTenant = (tenantId?: string) => {
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const updateUserDto = UserFactory.toCreateUpdateDto(user)
      const { data } = await axios.put<UserDto>(`/tenants/${tenantId}/users/${user.id}`, updateUserDto)
      return data
    },
    onSuccess: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants', tenantId, 'users'],
      })
    },
  })
}

export const useDeleteUserTenant = (tenantId?: string) => {
  return useMutation({
    mutationFn: async (userId: string) => {
      await axios.delete(`/tenants/${tenantId}/users/${userId}`)
    },
    onSuccess: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants', tenantId, 'users'],
      })
    },
  })
}
