import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { User } from 'api/domain/entities/user'
import { UserDto } from 'api/dto/user'
import axios from 'config/axios'
import { useAuth } from 'hooks/useAuth'
import { useCallback } from 'react'

export const useGetUserMe = () => {
  return useQuery({
    queryKey: ['users', 'me'],
    queryFn: async () => {
      const { data } = await axios.get<UserDto>('/users/me')
      return data
    },
    select: useCallback((data: UserDto) => new User(data), []),
    staleTime: 1000 * 60 * 5,
  })
}

export const useGetUser = (id?: string) => {
  return useQuery({
    queryKey: ['users', id],
    queryFn: async () => {
      const { data } = await axios.get<UserDto>(`/users/${id}`)
      return data
    },
    select: useCallback((data: UserDto) => new User(data), []),
    staleTime: 1000 * 60 * 5,
    enabled: !!id,
  })
}

export const useListUsers = (tenantId?: string) => {
  return useQuery({
    queryKey: ['tenants', tenantId, 'users'],
    queryFn: async () => {
      const { data } = await axios.get<UserDto[]>(`/tenants/${tenantId}/users`)
      return data
    },
    select: useCallback((data: UserDto[]) => data.map((u) => new User(u)), []),
    staleTime: 1000 * 60 * 5,
    enabled: !!tenantId,
  })
}

export const useSwitchTenant = () => {
  const { refresh } = useAuth()
  return useMutation({
    mutationFn: async (tenantId: string) => {
      const { data } = await axios.put<UserDto>('/switch-tenant', {
        tenantId,
      })
      return data
    },
    onSuccess: () => {
      void refresh().then(() => {
        void globalQueryClient.invalidateQueries({
          queryKey: ['users'],
        })
        void globalQueryClient.invalidateQueries({
          queryKey: ['tenants'],
        })
        void globalQueryClient.invalidateQueries({
          queryKey: ['recommendations'],
        })
      })
    },
  })
}

export const useUpdateUserMe = () => {
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const { data } = await axios.put<UserDto>('/users/me', user)
      return data
    },
    onSuccess: (data) => {
      void globalQueryClient.setQueryData(['users', 'me'], data)
    },
  })
}
