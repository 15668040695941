import { CostOptimizationRecommendationDifficulty } from 'api/domain/entities/recommendation/cost-optimization-recommendation'

export function getDifficultyColor(difficulty: CostOptimizationRecommendationDifficulty) {
  const difficultyColor = {
    [CostOptimizationRecommendationDifficulty.EASY]: 'green',
    [CostOptimizationRecommendationDifficulty.MEDIUM]: 'yellow',
    [CostOptimizationRecommendationDifficulty.ADVANCED]: 'red',
  }

  return difficultyColor[difficulty]
}
