/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Stack, useMantineTheme } from '@mantine/core'
import { IconSettings } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export function AdminLinks() {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isActive = pathname.includes('/admin')
  const currentColor = isActive ? theme.colors.gray[3] : theme.colors.gray[6]

  return (
    <Stack pl={12} pr={12}>
      <Button
        leftSection={<IconSettings />}
        justify="flex-start"
        h={54}
        size="1rem"
        c={currentColor}
        sx={(theme) => ({
          '&:hover': {
            backgroundColor: theme.colors.gray[7],
          },

          backgroundColor: isActive ? theme.colors.gray[7] : 'unset',
        })}
        onClick={() => navigate('/admin')}
      >
        {t('pages.admin')}
      </Button>
    </Stack>
  )
}
