import { Resource } from 'api/domain/entities/resource/resource'
import { ResourceDto } from './resource-dto'

export class ResourceAssembler {
  static fromDto(resource: Resource): ResourceDto {
    return {
      id: resource.id,
      resourceId: resource.resourceId,
      tenantId: resource.tenantId,
      accountId: resource.accountId,
      region: resource.region,
      service: resource.service,
      resourceIdShort: resource.resourceIdShort,
      resourceName: resource.resourceName,
      annualizedCost: resource.annualizedCost,
      last7DaysCost: resource.last7DaysCost,
      last31DaysCost: resource.last31DaysCost,
      additionalFields: resource.additionalFields,
    }
  }
}
