import { Box, Divider, Flex, Text, TextInput, Select, Group, Button } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Tenant } from 'api/domain/entities/tenant'
import { Provider, TenantStatus } from 'api/dto/tenant'
import { useUpdateTenant } from 'api/query/tenant'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ChevronIcon from 'assets/icons/chevron-right.svg?react'

interface AsideTenantContentProps {
  tenant: Tenant
  close: () => void
}

type PartialTenant = {
  name?: string
  provider?: Provider
  status?: TenantStatus
}

export function AsideEditTenantContent({ tenant, close }: AsideTenantContentProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: updateTenant } = useUpdateTenant()

  const form = useForm({
    initialValues: {
      ...tenant,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      return errors
    },
  })

  useEffect(() => {
    form.setValues({ ...tenant })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  async function onSubmit(partialTenant: PartialTenant) {
    setIsLoading(true)
    await updateTenant(partialTenant)
    close()
    form.reset()
    setIsLoading(false)
  }

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text c="dark.7" lh="24px" size="1rem" fw={700}>
          {t('tenant:edit.description')}
        </Text>
        <Box mt={10} display="flex">
          <TextInput withAsterisk w="100%" label={t('tenant:fields.name')} {...form.getInputProps('name')} />
        </Box>
        <Box mt={10} display="flex">
          <Select
            w="100%"
            label={t('tenant:fields.provider')}
            data={Object.entries(Provider).map(([key, value]) => ({
              label: t(`tenant:fields.provider-type.${value}`),
              value,
            }))}
            {...form.getInputProps('provider')}
          />
        </Box>
        <Box mt={10} display="flex">
          <Select
            w="100%"
            label={t('tenant:fields.status')}
            data={Object.entries(TenantStatus).map(([key, value]) => ({
              label: t(`tenant:fields.status-type.${key.toLowerCase()}`),
              value,
            }))}
            {...form.getInputProps('status')}
          />
        </Box>
        <Group mt={20} justify="flex-end">
          <Button loading={isLoading} type="submit" size="xs">
            {t('common.update')}
          </Button>
        </Group>
        <Divider mt={12} mb={12} />
        <Flex
          mt={10}
          p={12}
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            border: `1px solid ${theme.colors.gray[3]}`,
            cursor: 'pointer',
          })}
          justify="space-between"
          align="center"
          onClick={() => navigate(`/admin/tenants/${tenant.id}/accounts`)}
        >
          <Box>
            <Text c="dark.7" lh="21.7px" size="1rem" fw={700}>
              {t('tenant:fields.accounts')}
            </Text>
            <Text c="dark.2" lh="21.7px" size="0.625rem" fw={700}>
              {t('tenant:fields.accounts-in-tenant')}
            </Text>
          </Box>
          <ChevronIcon />
        </Flex>
        <Flex
          mt={10}
          p={12}
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            border: `1px solid ${theme.colors.gray[3]}`,
            cursor: 'pointer',
          })}
          justify="space-between"
          align="center"
          onClick={() => navigate(`/admin/tenants/${tenant.id}/users`)}
        >
          <Box>
            <Text c="dark.7" lh="21.7px" size="1rem" fw={700}>
              {t('tenant:fields.users')}
            </Text>
            <Text c="dark.2" lh="21.7px" size="0.625rem" fw={700}>
              {t('tenant:fields.users-in-tenant')}
            </Text>
          </Box>
          <ChevronIcon />
        </Flex>
      </form>
    </>
  )
}
