import { TenantStatus } from 'api/dto/tenant'
import { useGetTenantMe } from 'api/query/tenant'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const onboardingContext = createContext<ReturnType<typeof useProvideOnboarding>>({} as never)

export function ProvideOnboarding({ children }: { children: ReactNode }): JSX.Element {
  const onboarding = useProvideOnboarding()
  return <onboardingContext.Provider value={onboarding}>{children}</onboardingContext.Provider>
}

export const useOnboarding = () => useContext(onboardingContext)

export const useProvideOnboarding = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data: tenant } = useGetTenantMe()

  const redirectToCorrectStep = useCallback(() => {
    switch (tenant?.status) {
      case TenantStatus.ONBOARDING_STEP_1:
        if (location.pathname !== '/onboarding/setup') {
          navigate('/onboarding/regions')
        }
        break
      case TenantStatus.ONBOARDING_STEP_2:
        if (location.pathname !== '/onboarding/completed') {
          navigate('/onboarding/completed')
        }
        break
      case TenantStatus.ACTIVE:
        if (location.pathname !== '/dashboard') {
          navigate('/dashboard')
        }
        break
      default:
    }
  }, [tenant, navigate, location.pathname])

  return { redirectToCorrectStep }
}
