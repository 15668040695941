import { RecommendationType } from 'api/domain/entities/recommendation'
import { RecommendationDto } from './recommendation-dto'
import { CostOptimizationRecommendationAssembler } from './cost-optimization-recommendation-assembler'

export class RecommendationAssembler {
  static create(recommendationDto: RecommendationDto) {
    switch (recommendationDto.type) {
      case RecommendationType.COST_OPTIMIZATION:
        return new CostOptimizationRecommendationAssembler()
      default:
        return null
    }
  }
}
