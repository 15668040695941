export interface TenantBasicInfos {
  id: string
  name: string
}

export enum TenantStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ONBOARDING_STEP_1 = 'onboarding_step_1',
  ONBOARDING_STEP_2 = 'onboarding_step_2',
}

export enum Provider {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}

export interface TenantDto {
  id?: string
  name: string
  provider: Provider
  status: TenantStatus
}
