import { AppShell, Box, Group, Image, useMantineTheme } from '@mantine/core'
import { Outlet } from 'react-router'
import background from '../assets/images/login-bg-image.png'
import classes from './auth.layout.module.scss'

export function AuthLayout() {
  const theme = useMantineTheme()
  return (
    <AppShell
      bg={theme.colors['custom-dark'][0]}
      h="100vh"
      mih={1024}
      p="2.5rem"
      pos="relative"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Image src={background} alt="background" className={classes.background} />
      <Group h="100%" justify="right">
        <Box className={classes.box}>
          <Outlet />
        </Box>
      </Group>
    </AppShell>
  )
}
