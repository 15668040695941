import { AccessType } from '../access'
import { TenantBasicInfos } from '../tenant/tenant-dto'

export enum AppRole {
  EDITOR = 'editor',
  MANAGER = 'manager',
  OWNER = 'owner',
  USER = 'user',
}

export interface UserDto {
  id?: string
  accessType: AccessType
  currentTenantId: string
  email: string
  firstName: string
  lastName: string
  tenantIds: string[]
  role?: AppRole
  tenants?: TenantBasicInfos[]
  profilePicture?: string
}
