import { Loading } from 'components/loading/loading'
import { useAuth } from 'hooks/useAuth'
import { Outlet } from 'react-router-dom'

export const IndexLayout = () => {
  const { appIsHydrated } = useAuth()

  if (!appIsHydrated) {
    return <Loading size="xl" />
  }

  return <Outlet />
}
