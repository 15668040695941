import { I18nString } from 'api/dto/i18n-string'
import { RecommendationDto } from 'api/dto/recommendation/recommendation-dto'
import { RecommendationStatus } from './recommendation-status'
import { RecommendationType } from './recommendation-type'

import i18n from 'i18next'
import { DateTime } from 'utils/date-time'

export class Recommendation {
  id: string
  accountId: string
  assignedTo?: string
  description: I18nString
  dueDate?: DateTime
  evaluatedOn: DateTime
  fixedOn?: DateTime
  status: RecommendationStatus
  tenantId: string
  title: I18nString
  type: RecommendationType

  constructor(recommendationDto: RecommendationDto) {
    this.id = recommendationDto.id
    this.type = recommendationDto.type
    this.status = recommendationDto.status
    this.title = recommendationDto.title
    this.description = recommendationDto.description
    this.assignedTo = recommendationDto.assignedTo
    this.dueDate = recommendationDto.dueDate ? DateTime.fromISOString(recommendationDto.dueDate) : undefined
    this.evaluatedOn = DateTime.fromISOString(recommendationDto.evaluatedOn)
    this.fixedOn = recommendationDto.fixedOn ? DateTime.fromISOString(recommendationDto.fixedOn) : undefined
    this.accountId = recommendationDto.accountId
    this.tenantId = recommendationDto.tenantId
    this.assignedTo = recommendationDto.assignedTo
  }

  get isCostOptimization() {
    return this.type === RecommendationType.COST_OPTIMIZATION
  }

  get translatedTitle() {
    return this.title[i18n.language] || this.title.en
  }

  get translatedDescription() {
    return this.description[i18n.language] || this.description.en
  }
}
