import { UserDto } from './user-dto'
import { User } from 'api/domain/entities/user'

export class UserFactory {
  static fromDto(userDto: UserDto): User {
    return new User(userDto)
  }

  static toDto(user: Partial<User>): Partial<UserDto> {
    return {
      id: user.id,
      accessType: user.accessType,
      currentTenantId: user.currentTenantId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      tenantIds: user.tenantIds,
      tenants: user.tenants,
    }
  }

  static toCreateUpdateDto(user: Partial<User>): Partial<UserDto> {
    return {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
    }
  }
}
