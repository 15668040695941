import { useAuth } from 'hooks/useAuth'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export function RequireAuthentication({ children, needsAdminRight }: { children: ReactNode | ReactNode[]; needsAdminRight?: boolean }) {
  const { isAuthenticated, appIsHydrated, user } = useAuth()
  const location = useLocation()

  if (!isAuthenticated && appIsHydrated) {
    return <Navigate to={'/session/login'} state={{ from: location }} replace />
  }

  if (needsAdminRight && !user?.isAdmin) {
    return <Navigate to={'/dashboard'} state={{ from: location }} replace />
  }

  return <>{children}</>
}
