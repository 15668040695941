import { TextInput, Button, Text, Box, Anchor } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { NS } from 'i18n'

interface FormValues {
  email: string
}

export const ForgotPassword = () => {
  const { forgotPassword } = useAuth()
  const { t } = useTranslation(NS.session)
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    const { email } = values
    setIsLoading(true)
    await forgotPassword(email)
    setIsLoading(false)
  }

  return (
    <Box display="flex" p="0 110px 39px" style={{ flex: 1, flexFlow: 'column', padding: '20px 30px', zIndex: 100 }}>
      <Box mt={183} mb={40} display="flex" w="100%" style={{ justifyContent: 'center', flexDirection: 'column' }}>
        <Text mb={12} fw={700} size="40px">
          {t('forgot-password.title')}
        </Text>
      </Box>

      <ForgotPasswordForm form={form} isLoading={isLoading} onSubmit={onSubmit} />

      <Box flex={0}>
        <Text c="#909296" ta="center" size=".875rem">
          {t('forgot-password.already-have-account')}{' '}
          <Anchor href="/session/login" size=".875rem" c="#2c2e33">
            {t(`${NS.translation}:common.login`)}
          </Anchor>
        </Text>
      </Box>
    </Box>
  )
}

interface ForgotPasswordFormProps {
  form: UseFormReturnType<
    {
      email: string
    },
    (values: { email: string }) => {
      email: string
    }
  >
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
}

function ForgotPasswordForm({ form, isLoading, onSubmit }: ForgotPasswordFormProps) {
  const { t } = useTranslation(NS.session)

  return (
    <Box display="flex" w="100%" flex={1}>
      <form style={{ width: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
        <Box mb={30}>
          <TextInput w="100%" label={t('login.email') || ''} {...form.getInputProps('email')} />
        </Box>

        <Button w="100%" mb={8} color="#868E96" radius="sm" type="submit" loading={isLoading}>
          <Text size=".875rem">{t(`${NS.translation}:common.forgot-password`)}</Text>
        </Button>
      </form>
    </Box>
  )
}
