import { CostOptimizationRecommendation } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { CostOptimizationRecommendationDto } from './recommendation-dto'

export class CostOptimizationRecommendationAssembler {
  fromDto(recommendationDto: CostOptimizationRecommendationDto) {
    return new CostOptimizationRecommendation(recommendationDto)
  }

  toDto(recommendation: Partial<CostOptimizationRecommendation>): Partial<CostOptimizationRecommendationDto> {
    return {
      id: recommendation.id,
      type: recommendation.type,
      status: recommendation.status,
      title: recommendation.title,
      description: recommendation.description,
      evaluatedOn: recommendation.evaluatedOn?.toISOString(),
      accountId: recommendation.accountId,
      tenantId: recommendation.tenantId,
      assignedTo: recommendation.assignedTo,
      resources: recommendation.resources,
      globalCurrentCost: recommendation.globalCurrentCost,
      globalAnnualizedSavings: recommendation.globalAnnualizedSavings,
      difficulty: recommendation.difficulty,
      dueDate: recommendation.dueDate?.toISOString(),
      fixedOn: recommendation.fixedOn?.toISOString(),
    }
  }
}
