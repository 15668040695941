import { useGetTenantMe } from 'api/query/tenant'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export function RequireOnboarding({ children }: { children: ReactNode | ReactNode[] }) {
  const location = useLocation()
  const { data: tenant } = useGetTenantMe()

  if (!tenant) {
    return
  }

  if (tenant.needsOnboarding() && !location.pathname.includes('/onboarding')) {
    return <Navigate to={'/onboarding'} state={{ from: location }} replace />
  }

  return <>{children}</>
}
