import { Box, Button, Drawer, Group, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { AppRole } from 'api/dto/user'
import { useCreateUserTenant } from 'api/query/tenant'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface AddUserProps {
  opened: boolean
  close: () => void
}

type PartialUser = {
  firstName: string
  lastName: string
  email: string
  role: AppRole
}

export function AddUserDrawer({ opened, close }: AddUserProps) {
  const { id } = useParams()
  const { t } = useTranslation()
  const { mutateAsync: createUser } = useCreateUserTenant(id)
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: AppRole.USER,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.firstName || values.firstName.length < 1) {
        errors.firstName = t('common.required')
      }

      if (!values.lastName || values.lastName.length < 1) {
        errors.lastName = t('common.required')
      }

      if (!values.email || values.email.length < 1) {
        errors.email = t('common.required')
      }

      if (!values.role || values.role.length < 1) {
        errors.role = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialUser: PartialUser) {
    setIsLoading(true)
    await createUser(partialUser)
    close()
    form.reset()
    setIsLoading(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('user:add.title')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('user:add.description')}</Text>
              <TextInput mt={10} withAsterisk w="100%" label={t('user:fields.firstName')} {...form.getInputProps('firstName')} />
              <TextInput mt={10} withAsterisk w="100%" label={t('user:fields.lastName')} {...form.getInputProps('lastName')} />
              <TextInput mt={10} type="email" withAsterisk w="100%" label={t('user:fields.email')} {...form.getInputProps('email')} />
              <Select
                mt={10}
                w="100%"
                label={t('user:fields.app-role')}
                data={Object.entries(AppRole).map(([key, value]) => ({
                  label: t(`user:fields.app-role-type.${value}`),
                  value,
                }))}
                {...form.getInputProps('role')}
              />
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button loading={isLoading} type="submit" size="xs">
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
