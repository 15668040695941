import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { AWSAccountDto, AWSAccount } from 'api/dto/aws-account'
import axios from 'config/axios'
import { useCallback } from 'react'

export const useGetOnboardingTemplate = () => {
  return useQuery({
    queryKey: ['onboarding'],
    queryFn: async () => {
      const { data } = await axios.get('/onboarding')
      return data
    },
  })
}

export const useAwsAccounts = () => {
  return useQuery({
    queryKey: ['onboarding-aws-account'],
    queryFn: async () => {
      const { data } = await axios.get<AWSAccountDto[]>('/onboarding/aws/accounts')
      return data
    },
    select: useCallback((awsAccounts: AWSAccountDto[]) => AWSAccount.fromDtoList(awsAccounts), []),
  })
}

export const useGenerateOnboardingTemplate = () => {
  return useMutation({
    mutationFn: async (regions: string[]) => {
      const { data } = await axios.post<string>('/onboarding/generate', {
        regions,
      })
      return data
    },
  })
}

export const useCompleteOnboarding = () => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.post('/onboarding/complete')
      return data
    },
    onSettled: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants', 'me'],
      })
    },
  })
}
