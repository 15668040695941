import { Box, Progress } from '@mantine/core'
import { PasswordRequirement } from './password-requirement'
import { useTranslation } from 'react-i18next'
import { NS } from 'i18n'

interface PasswordProgressProps {
  value: string
}

function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1

  requirements().forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const requirements = (t?: (key: string) => string | null) => [
  { re: /.{8,}/, label: t?.('register.password-requirements.minimum') },
  { re: /[0-9]/, label: t?.('register.password-requirements.number') },
  { re: /[a-z]/, label: t?.('register.password-requirements.lowercase') },
  { re: /[A-Z]/, label: t?.('register.password-requirements.uppercase') },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: t?.('register.password-requirements.special') },
]

export function PasswordProgress({ value }: PasswordProgressProps) {
  const { t } = useTranslation(NS.session)

  const strength = getStrength(value)
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red'

  const checks = requirements(t).map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label || ''} meets={requirement.re.test(value)} />
  ))

  return (
    <Box mb={20}>
      <Progress color={color} value={strength} size={5} mb="xs" />
      {checks}
    </Box>
  )
}
