import { Box } from '@mantine/core'
import classes from './widget.module.scss'

interface WidgetProps {
  children?: React.ReactNode
}

export function Widget(props: WidgetProps) {
  return (
    <Box p={10} className={classes.widget}>
      {props.children}
    </Box>
  )
}
