import { AccessType } from 'api/dto/access'
import { TenantBasicInfos } from 'api/dto/tenant'
import { AppRole, UserDto } from 'api/dto/user'

export class User {
  id?: string
  accessType: AccessType
  currentTenantId: string
  email: string
  firstName: string
  lastName: string
  tenantIds: string[]
  role?: AppRole
  tenants?: TenantBasicInfos[]
  profilePicture?: string

  constructor(user: UserDto) {
    this.id = user.id
    this.accessType = user.accessType
    this.currentTenantId = user.currentTenantId
    this.email = user.email
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.tenantIds = user.tenantIds
    this.role = user.role
    this.tenants = user.tenants
    this.profilePicture = user.profilePicture
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get initials() {
    return `${this.firstName[0].toUpperCase()}${this.lastName[0].toUpperCase()}`
  }
}
