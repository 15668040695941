import { Box, Image, Text, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { NS } from 'i18n'
import { HelpIcon } from 'components/images/help-icon'

export const HelpBlock = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  return (
    <>
      <HelpIcon />
      <Text fw={700} fz={'0.875rem'}>
        {t(`${NS.onboarding}:sidebar.helpBlock.title`)}
      </Text>
      <Box maw={242}>
        <Text fw={400} fz={'0.875rem'}>
          {t(`${NS.onboarding}:sidebar.helpBlock.description`)}
        </Text>
      </Box>
    </>
  )
}
