import { Box, Button, Drawer, Group, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Provider, TenantStatus } from 'api/dto/tenant'
import { useCreateTenant } from 'api/query/tenant'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddTenantProps {
  opened: boolean
  close: () => void
}

type PartialTenant = {
  id: string
  name: string
  provider: Provider
}

export function AddTenantDrawer({ opened, close }: AddTenantProps) {
  const { t } = useTranslation()
  const { mutateAsync: createTenant } = useCreateTenant()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      id: '',
      name: '',
      provider: Provider.AWS,
      status: TenantStatus.INACTIVE,
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.provider) {
        errors.provider = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialTenant: PartialTenant) {
    setIsLoading(true)
    await createTenant(partialTenant)
    close()
    form.reset()
    setIsLoading(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('tenant:add.title')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('tenant:add.description')}</Text>
              <Box mt={10} display="flex">
                <TextInput withAsterisk w="100%" label={t('tenant:fields.id')} {...form.getInputProps('id')} />
              </Box>
              <Box mt={10} display="flex">
                <TextInput withAsterisk w="100%" label={t('tenant:fields.name')} {...form.getInputProps('name')} />
              </Box>
              <Box mt={10} display="flex">
                <Select
                  w="100%"
                  label={t('tenant:fields.provider')}
                  data={Object.entries(Provider).map(([key, value]) => ({
                    label: t(`tenant:fields.provider-type.${value}`),
                    value,
                  }))}
                  {...form.getInputProps('provider')}
                />
              </Box>
              <Box mt={10} display="flex">
                <Select
                  w="100%"
                  label={t('tenant:fields.status')}
                  data={Object.entries(TenantStatus).map(([key, value]) => ({
                    label: t(`tenant:fields.status-type.${key.toLowerCase()}`),
                    value,
                  }))}
                  {...form.getInputProps('status')}
                />
              </Box>
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button loading={isLoading} type="submit" size="xs">
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}
