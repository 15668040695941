import {
  CostOptimizationRecommendation,
  CostOptimizationRecommendationDifficulty,
  CostOptimizationResource,
} from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { t } from 'i18next'

export enum Savings {
  POTENTIAL = 'potential',
  REALIZED = 'realized',
}

export function generateBarChartData(resources: CostOptimizationResource[]) {
  const resourcesByService = resources?.reduce(
    (acc, resource) => {
      if (!resource.service) {
        return {}
      }
      if (!acc[resource.service]) {
        acc[resource.service] = []
      }
      acc[resource.service].push(resource)
      return acc
    },
    {} as Record<string, CostOptimizationResource[]>,
  )

  const chartData = Object.entries(resourcesByService || {}).map(([service, resources]) => {
    const totalCost = resources.reduce((sum, resource) => sum + (resource.last31DaysCost || 0), 0)
    return { service, totalCost }
  })

  chartData.sort((a, b) => a.totalCost - b.totalCost)

  return chartData
}

export function generateSavingsData(recommendations: CostOptimizationRecommendation[], savingsType: Savings) {
  const resourcesByDifficulty = recommendations?.reduce(
    (acc, recommendation) => {
      if (!acc[recommendation.difficulty]) {
        acc[recommendation.difficulty] = []
      }

      if (!recommendation.difficulty) {
        console.log('recommendation', recommendation)
      }

      acc[recommendation.difficulty].push(recommendation)
      return acc
    },
    {} as Record<string, CostOptimizationRecommendation[]>,
  )

  const colors = {
    [CostOptimizationRecommendationDifficulty.EASY]: 'green',
    [CostOptimizationRecommendationDifficulty.MEDIUM]: 'orange',
    [CostOptimizationRecommendationDifficulty.ADVANCED]: 'red',
  }

  const chartData = Object.entries(resourcesByDifficulty || {}).map(([difficulty, recommendations]) => {
    const totalCost = recommendations.reduce((sum, recommendation) => {
      if (savingsType === Savings.POTENTIAL) {
        return sum + recommendation.globalAnnualizedSavings
      }
      return sum + recommendation.globalRealizedSavings
    }, 0)
    return {
      name: t(`cost:fields.difficulty.${difficulty}`).toUpperCase(),
      value: totalCost,
      color: colors[difficulty as CostOptimizationRecommendationDifficulty],
    }
  })

  if (chartData.every((data) => data.value === 0)) {
    return {
      byDifficulty: [
        {
          name: 'No data',
          value: 1,
          color: 'gray',
        },
      ],
      total: [
        {
          name: 'No data',
          value: 1,
          color: 'gray',
        },
      ],
    }
  }

  // sort easy, medium, advance
  const order = [
    t(`cost:fields.difficulty.${CostOptimizationRecommendationDifficulty.EASY}`).toUpperCase(),
    t(`cost:fields.difficulty.${CostOptimizationRecommendationDifficulty.MEDIUM}`).toUpperCase(),
    t(`cost:fields.difficulty.${CostOptimizationRecommendationDifficulty.ADVANCED}`).toUpperCase(),
  ]

  chartData.sort((a, b) => {
    return order.indexOf(a.name) - order.indexOf(b.name)
  })

  return {
    byDifficulty: chartData,
    total: [
      {
        name: 'Total',
        value: chartData.reduce((sum, data) => sum + data.value, 0),
        color: 'green',
      },
    ],
  }
}
