import { StepperProps, Stepper, Image, useMantineTheme, rem } from '@mantine/core'
import PlusIcon from '../../../assets/icons/plus.png'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NS } from 'i18n'
import { isCurrentStep, OnboardingSteps } from 'utils/steps'

interface Props {
  activeStep: number
}

const StyledStepper = (props: StepperProps) => {
  const theme = useMantineTheme()

  return (
    <Stepper
      styles={{
        step: {
          padding: 0,
          marginTop: '-3px',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '--separator-spacing': '70px',
        },
        stepWrapper: {
          width: '20px',
        },

        stepIcon: {
          minWidth: 'unset',
          minHeight: 'unset',
          width: '20px',
          height: '20px',
          zIndex: 1,
        },

        stepLabel: {
          fontSize: '1.25rem',
          fontWeight: 700,
          transform: 'translateY(-2px)',
          color: 'white',
        },

        stepDescription: {
          fontSize: '1rem',
          fontWeight: 400,
          color: 'white',
        },

        verticalSeparator: {
          borderWidth: '6px',
          transform: 'translateX(-14px)',
          top: 'unset',
          marginTop: rem(-2),
          marginBottom: rem(-2),
        },
      }}
      {...props}
    />
  )
}

export const OnboardingStepper = ({ activeStep }: Props) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()

  const stepIcon = useMemo(() => {
    return <Image src={PlusIcon} width={12} height={12} />
  }, [])

  const computedStep = useMemo(() => {
    // Mantine stepper start with 0
    return activeStep > 0 ? activeStep - 1 : 0
  }, [activeStep])

  return (
    <StyledStepper mt={40} active={computedStep} orientation="vertical" color="custom-magenta">
      <Stepper.Step
        label={t(`${NS.onboarding}:sidebar.stepper.stepTitle.1`)}
        description={t(`${NS.onboarding}:sidebar.stepper.stepDescription.onboardOrg`)}
        icon={stepIcon}
        completedIcon={stepIcon}
        styles={
          isCurrentStep(activeStep, OnboardingSteps.Setup) ? { stepIcon: { backgroundColor: theme.colors['custom-magenta'][0] } } : {}
        }
      />

      <Stepper.Step
        label={t(`${NS.onboarding}:sidebar.stepper.stepTitle.2`)}
        description={t(`${NS.onboarding}:sidebar.stepper.stepDescription.finish`)}
        icon={stepIcon}
        completedIcon={stepIcon}
        styles={
          isCurrentStep(activeStep, OnboardingSteps.Completed) ? { stepIcon: { backgroundColor: theme.colors['custom-magenta'][0] } } : {}
        }
      />
    </StyledStepper>
  )
}
