import { useQuery } from '@tanstack/react-query'
import { ResourceAssembler } from 'api/dto/resource/resource-assembler'
import { ResourceDto } from 'api/dto/resource/resource-dto'
import { PaginatedResult } from 'api/utils/pagination'
import axios from 'config/axios'
import { useCallback } from 'react'

export const useListResources = (tenantId?: string, limit?: number) =>
  useQuery({
    queryKey: ['resources', tenantId],
    queryFn: async () => {
      const { data } = await axios.get<PaginatedResult<ResourceDto>>(`/tenants/${tenantId}/resources?limit=${limit}`)
      return data.items
    },
    select: useCallback((resources: ResourceDto[]) => {
      return resources.map(ResourceAssembler.fromDto)
    }, []),
    enabled: !!tenantId,
    staleTime: 1000 * 60 * 5,
  })
