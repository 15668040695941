/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Stack, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import DashboardIcon from 'assets/icons/dashboard-icon.svg?react'
import { FunctionComponent, SVGProps } from 'react'
import { IconMoneybag } from '@tabler/icons-react'

interface MainLinkProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }> | JSX.Element

  color?: string
  label: string

  href: string
}

function MainLink({ icon, color, label, href }: MainLinkProps) {
  const theme = useMantineTheme()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isActive = pathname.includes(href)
  const currentColor = isActive ? theme.colors.gray[3] : theme.colors.gray[6]

  return (
    <Button
      leftSection={typeof icon == 'function' ? icon({ fill: currentColor }) : icon}
      justify="flex-start"
      h={54}
      size="1rem"
      c={currentColor}
      sx={(theme) => ({
        '&:hover': {
          backgroundColor: theme.colors.gray[7],
        },

        backgroundColor: isActive ? theme.colors.gray[7] : 'unset',
      })}
      onClick={() => navigate(href)}
    >
      {label}
    </Button>
  )
}

const links = (t: (key: string) => string) => [
  {
    icon: DashboardIcon,
    label: t('pages.dashboard'),
    href: '/dashboard',
  },
  {
    icon: <IconMoneybag />,
    label: t('pages.cost-and-usage'),
    href: '/cost-recommendations',
  },
]

export function MainLinks() {
  const { t } = useTranslation()

  const mainLinks = links(t).map((link) => <MainLink {...link} key={link.label} />)
  return (
    <Stack pl={12} pr={12}>
      {mainLinks}
    </Stack>
  )
}
