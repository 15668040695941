import { TextInput, Button, Text, Box, Anchor } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { NS } from 'i18n'
import { PasswordProgress } from 'components/password/password-progress'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { encryptData } from 'utils/crypto'

interface FormValues {
  encryptedData: string
  password: string
  confirmPassword: string
}

export const ResetPassword = () => {
  const { completeForgotPassword } = useAuth()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation(NS.session)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      encryptedData: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => (value.length >= 8 ? null : 'Password is too short'),
      confirmPassword: (value, values) => (value === values.password ? null : 'Passwords do not match'),
    },
  })

  useEffect(() => {
    const encryptedData = searchParams.get('_d')

    if (!encryptedData) {
      navigate('/session/login')
      return
    }

    form.setFieldValue('encryptedData', encryptedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  async function onSubmit(values: FormValues) {
    const { encryptedData, password } = values
    setIsLoading(true)

    const encryptedPassword = encryptData({ password }, encryptedData)

    await completeForgotPassword(encryptedData, encryptedPassword)
    setIsLoading(false)
  }

  return (
    <Box display="flex" p="0 110px 39px" style={{ flex: 1, flexFlow: 'column', padding: '20px 30px', zIndex: 100 }}>
      <Box mt={183} mb={40} display="flex" w="100%" style={{ justifyContent: 'center', flexDirection: 'column' }}>
        <Text mb={12} fw={700} size="40px">
          {t('reset-password.title')}
        </Text>
      </Box>

      <ResetPasswordForm form={form} isLoading={isLoading} onSubmit={onSubmit} />

      <Box flex={0}>
        <Text c="#909296" ta="center" size=".875rem">
          {t('register.already-have-account')}
          <Anchor ml={5} href="/session/login" size=".875rem" c="#2c2e33">
            {t(`${NS.translation}:common.login`)}
          </Anchor>
        </Text>
      </Box>
    </Box>
  )
}

interface ResetPasswordFormProps {
  form: UseFormReturnType<
    {
      encryptedData: string
      password: string
      confirmPassword: string
    },
    (values: { encryptedData: string; password: string; confirmPassword: string }) => {
      encryptedData: string
      password: string
      confirmPassword: string
    }
  >
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
}

function ResetPasswordForm({ form, isLoading, onSubmit }: ResetPasswordFormProps) {
  const { t } = useTranslation(NS.session)

  const value = form.values.password

  return (
    <Box display="flex" w="100%" flex={1}>
      <form style={{ width: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
        <TextInput mb={20} w="100%" type="password" label={t('reset-password.password') || ''} {...form.getInputProps('password')} />
        <PasswordProgress value={value} />

        <TextInput
          mb={33}
          w="100%"
          type="password"
          label={t('reset-password.confirmPassword') || ''}
          {...form.getInputProps('confirmPassword')}
        />

        <Button w="100%" mb={8} color="#868E96" radius="sm" type="submit" loading={isLoading}>
          <Text size=".875rem">{t(`${NS.translation}:common.reset-password`)}</Text>
        </Button>
      </form>
    </Box>
  )
}
